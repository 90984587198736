/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createCustomer } from '../fn/customer-overview-controller/create-customer';
import { CreateCustomer$Params } from '../fn/customer-overview-controller/create-customer';
import { createOrUpdateNoteByCustomerId } from '../fn/customer-overview-controller/create-or-update-note-by-customer-id';
import { CreateOrUpdateNoteByCustomerId$Params } from '../fn/customer-overview-controller/create-or-update-note-by-customer-id';
import { CustomerDto } from '../models/customer-dto';
import { CustomerOverviewDto } from '../models/customer-overview-dto';
import { getCustomerById } from '../fn/customer-overview-controller/get-customer-by-id';
import { GetCustomerById$Params } from '../fn/customer-overview-controller/get-customer-by-id';
import { getCustomerOverviewById } from '../fn/customer-overview-controller/get-customer-overview-by-id';
import { GetCustomerOverviewById$Params } from '../fn/customer-overview-controller/get-customer-overview-by-id';
import { getCustomers } from '../fn/customer-overview-controller/get-customers';
import { GetCustomers$Params } from '../fn/customer-overview-controller/get-customers';
import { getCustomersForSecurityExperts } from '../fn/customer-overview-controller/get-customers-for-security-experts';
import { GetCustomersForSecurityExperts$Params } from '../fn/customer-overview-controller/get-customers-for-security-experts';
import { getIndustryBranches } from '../fn/customer-overview-controller/get-industry-branches';
import { GetIndustryBranches$Params } from '../fn/customer-overview-controller/get-industry-branches';
import { getNewCustomers } from '../fn/customer-overview-controller/get-new-customers';
import { GetNewCustomers$Params } from '../fn/customer-overview-controller/get-new-customers';
import { getNoteByCustomerId } from '../fn/customer-overview-controller/get-note-by-customer-id';
import { GetNoteByCustomerId$Params } from '../fn/customer-overview-controller/get-note-by-customer-id';
import { IndustryBranchDto } from '../models/industry-branch-dto';
import { NoteDto } from '../models/note-dto';
import { patchBranch } from '../fn/customer-overview-controller/patch-branch';
import { PatchBranch$Params } from '../fn/customer-overview-controller/patch-branch';
import { ResponseCustomerOverviewDto } from '../models/response-customer-overview-dto';
import { ResponseIndustryBranchDto } from '../models/response-industry-branch-dto';
import { ResponseMediumCustomerDto } from '../models/response-medium-customer-dto';
import { TagDto } from '../models/tag-dto';
import { updateNumberOfEmployees } from '../fn/customer-overview-controller/update-number-of-employees';
import { UpdateNumberOfEmployees$Params } from '../fn/customer-overview-controller/update-number-of-employees';
import { updateSvgDetails } from '../fn/customer-overview-controller/update-svg-details';
import { UpdateSvgDetails$Params } from '../fn/customer-overview-controller/update-svg-details';
import { updateTags } from '../fn/customer-overview-controller/update-tags';
import { UpdateTags$Params } from '../fn/customer-overview-controller/update-tags';


/**
 * Manages the customer CRUD, industry branch repository
 */
@Injectable({ providedIn: 'root' })
export class CustomerOverviewControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCustomers()` */
  static readonly GetCustomersPath = '/customers';

  /**
   * Returns a list of all customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Response(params?: GetCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseCustomerOverviewDto>> {
    return getCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a list of all customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers(params?: GetCustomers$Params, context?: HttpContext): Observable<ResponseCustomerOverviewDto> {
    return this.getCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseCustomerOverviewDto>): ResponseCustomerOverviewDto => r.body)
    );
  }

  /** Path part for operation `createCustomer()` */
  static readonly CreateCustomerPath = '/customers';

  /**
   * Creates a new free customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomer$Response(params: CreateCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerDto>> {
    return createCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new free customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomer(params: CreateCustomer$Params, context?: HttpContext): Observable<CustomerDto> {
    return this.createCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerDto>): CustomerDto => r.body)
    );
  }

  /** Path part for operation `updateTags()` */
  static readonly UpdateTagsPath = '/customers/{id}/tags';

  /**
   * Update tags of customer, replace existing tags by this list of tags
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTags()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTags$Response(params: UpdateTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagDto>>> {
    return updateTags(this.http, this.rootUrl, params, context);
  }

  /**
   * Update tags of customer, replace existing tags by this list of tags
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTags$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTags(params: UpdateTags$Params, context?: HttpContext): Observable<Array<TagDto>> {
    return this.updateTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagDto>>): Array<TagDto> => r.body)
    );
  }

  /** Path part for operation `updateSvgDetails()` */
  static readonly UpdateSvgDetailsPath = '/customers/{id}/svg-details';

  /**
   * Updates svg details (like debitor, invoiceEmail)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSvgDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSvgDetails$Response(params: UpdateSvgDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerDto>> {
    return updateSvgDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates svg details (like debitor, invoiceEmail)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSvgDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSvgDetails(params: UpdateSvgDetails$Params, context?: HttpContext): Observable<CustomerDto> {
    return this.updateSvgDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerDto>): CustomerDto => r.body)
    );
  }

  /** Path part for operation `updateNumberOfEmployees()` */
  static readonly UpdateNumberOfEmployeesPath = '/customers/{id}/number-employees';

  /**
   * Updates number employees of a Customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNumberOfEmployees()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNumberOfEmployees$Response(params: UpdateNumberOfEmployees$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerDto>> {
    return updateNumberOfEmployees(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates number employees of a Customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateNumberOfEmployees$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNumberOfEmployees(params: UpdateNumberOfEmployees$Params, context?: HttpContext): Observable<CustomerDto> {
    return this.updateNumberOfEmployees$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerDto>): CustomerDto => r.body)
    );
  }

  /** Path part for operation `getNoteByCustomerId()` */
  static readonly GetNoteByCustomerIdPath = '/customers/{id}/note';

  /**
   * Get note by customer id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNoteByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoteByCustomerId$Response(params: GetNoteByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<NoteDto>> {
    return getNoteByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Get note by customer id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNoteByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoteByCustomerId(params: GetNoteByCustomerId$Params, context?: HttpContext): Observable<NoteDto> {
    return this.getNoteByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoteDto>): NoteDto => r.body)
    );
  }

  /** Path part for operation `createOrUpdateNoteByCustomerId()` */
  static readonly CreateOrUpdateNoteByCustomerIdPath = '/customers/{id}/note';

  /**
   * Create or update note by customer id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateNoteByCustomerId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateNoteByCustomerId$Response(params: CreateOrUpdateNoteByCustomerId$Params, context?: HttpContext): Observable<StrictHttpResponse<NoteDto>> {
    return createOrUpdateNoteByCustomerId(this.http, this.rootUrl, params, context);
  }

  /**
   * Create or update note by customer id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateNoteByCustomerId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateNoteByCustomerId(params: CreateOrUpdateNoteByCustomerId$Params, context?: HttpContext): Observable<NoteDto> {
    return this.createOrUpdateNoteByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<NoteDto>): NoteDto => r.body)
    );
  }

  /** Path part for operation `patchBranch()` */
  static readonly PatchBranchPath = '/customers/{id}/industry-branch';

  /**
   * Updates the industry branch of the customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBranch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBranch$Response(params: PatchBranch$Params, context?: HttpContext): Observable<StrictHttpResponse<IndustryBranchDto>> {
    return patchBranch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the industry branch of the customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBranch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchBranch(params: PatchBranch$Params, context?: HttpContext): Observable<IndustryBranchDto> {
    return this.patchBranch$Response(params, context).pipe(
      map((r: StrictHttpResponse<IndustryBranchDto>): IndustryBranchDto => r.body)
    );
  }

  /** Path part for operation `getCustomerById()` */
  static readonly GetCustomerByIdPath = '/customers/{id}';

  /**
   * Get customer by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById$Response(params: GetCustomerById$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerDto>> {
    return getCustomerById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customer by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById(params: GetCustomerById$Params, context?: HttpContext): Observable<CustomerDto> {
    return this.getCustomerById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerDto>): CustomerDto => r.body)
    );
  }

  /** Path part for operation `getCustomerOverviewById()` */
  static readonly GetCustomerOverviewByIdPath = '/customers/{id}/overview';

  /**
   * Get customer by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerOverviewById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerOverviewById$Response(params: GetCustomerOverviewById$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerOverviewDto>> {
    return getCustomerOverviewById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get customer by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerOverviewById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerOverviewById(params: GetCustomerOverviewById$Params, context?: HttpContext): Observable<CustomerOverviewDto> {
    return this.getCustomerOverviewById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerOverviewDto>): CustomerOverviewDto => r.body)
    );
  }

  /** Path part for operation `getIndustryBranches()` */
  static readonly GetIndustryBranchesPath = '/customers/selectable-industry-branches';

  /**
   * Get selectable industry branches (gwz)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIndustryBranches()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndustryBranches$Response(params?: GetIndustryBranches$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseIndustryBranchDto>> {
    return getIndustryBranches(this.http, this.rootUrl, params, context);
  }

  /**
   * Get selectable industry branches (gwz)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIndustryBranches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIndustryBranches(params?: GetIndustryBranches$Params, context?: HttpContext): Observable<ResponseIndustryBranchDto> {
    return this.getIndustryBranches$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseIndustryBranchDto>): ResponseIndustryBranchDto => r.body)
    );
  }

  /** Path part for operation `getNewCustomers()` */
  static readonly GetNewCustomersPath = '/customers/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCustomers$Response(params: GetNewCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMediumCustomerDto>> {
    return getNewCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewCustomers(params: GetNewCustomers$Params, context?: HttpContext): Observable<ResponseMediumCustomerDto> {
    return this.getNewCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseMediumCustomerDto>): ResponseMediumCustomerDto => r.body)
    );
  }

  /** Path part for operation `getCustomersForSecurityExperts()` */
  static readonly GetCustomersForSecurityExpertsPath = '/customers/by-security-expert/{securityExpertId}';

  /**
   * Returns a compacted list of all customers specifically for sifa assignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersForSecurityExperts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersForSecurityExperts$Response(params: GetCustomersForSecurityExperts$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMediumCustomerDto>> {
    return getCustomersForSecurityExperts(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a compacted list of all customers specifically for sifa assignment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomersForSecurityExperts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersForSecurityExperts(params: GetCustomersForSecurityExperts$Params, context?: HttpContext): Observable<ResponseMediumCustomerDto> {
    return this.getCustomersForSecurityExperts$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseMediumCustomerDto>): ResponseMediumCustomerDto => r.body)
    );
  }

}
