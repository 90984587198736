/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResponseMediumCustomerDto } from '../../models/response-medium-customer-dto';

export interface GetNewCustomers$Params {
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';

/**
 * filter for new customers
 */
  newCustomer: boolean;
}

export function getNewCustomers(http: HttpClient, rootUrl: string, params: GetNewCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<ResponseMediumCustomerDto>> {
  const rb = new RequestBuilder(rootUrl, getNewCustomers.PATH, 'get');
  if (params) {
    rb.query('limit', params.limit, {});
    rb.query('offset', params.offset, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
    rb.query('newCustomer', params.newCustomer, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResponseMediumCustomerDto>;
    })
  );
}

getNewCustomers.PATH = '/customers/new';
